.Inicio_CardPerfil {
    border-bottom: 0.15em solid #e1e1e1;
}
/* 
@media screen and (min-width: 601px) and (max-width: 992px) {
    .Inicio_CardPerfil {
        border-right: 0.1em solid #e1e1e1;
        border-bottom: none;
    }
}

@media screen and (min-width: 993px) {
    .Inicio_CardPerfil {
        border-right: 0.1em solid #e1e1e1;
        border-bottom: none;
    }
} */