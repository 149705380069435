.LoadWrap_Audio {
    position: relative;
}

.LoadWrap_Audio span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: rgba(162, 162, 162, 0.25);
    position: absolute;
    animation: preloader_audio 1.5s infinite ease-in-out;
}

.LoadWrap_Audio span:nth-child(2) {
    left: 11px;
    animation-delay: .2s;
}

.LoadWrap_Audio span:nth-child(3) {
    left: 22px;
    animation-delay: .4s;
}

.LoadWrap_Audio span:nth-child(4) {
    left: 33px;
    animation-delay: .6s;
}

.LoadWrap_Audio span:nth-child(5) {
    left: 44px;
    animation-delay: .8s;
}

@keyframes preloader_audio {
    0% {
        height: 5px;
        transform: translateY(0px);
        background: rgba(162, 162, 162, 0.25);
    }
    25% {
        height: 30px;
        transform: translateY(15px);
        background: #3f92ba;
    }
    50% {
        height: 5px;
        transform: translateY(0px);
        background: rgba(162, 162, 162, 0.25);
    }
    100% {
        height: 5px;
        transform: translateY(0px);
        background: rgba(162, 162, 162, 0.25);
    }
}

/* Tipo Windows */

.LoadWrap_Windows {
    position: relative;
    width: 42px;
    height: 42px;
    animation: preloader_6 5s infinite linear;
}

.LoadWrap_Windows span {
    width: 20px;
    height: 20px;
    position: absolute;
    background: red;
    display: block;
    animation: preloader_6_span 1s infinite linear;
}

.LoadWrap_Windows span:nth-child(1) {
    background: rgba(162, 162, 162, 0.25);
}

.LoadWrap_Windows span:nth-child(2) {
    left: 22px;
    background: rgba(162, 162, 162, 0.25);
    animation-delay: .2s;
}

.LoadWrap_Windows span:nth-child(3) {
    top: 22px;
    background: rgba(162, 162, 162, 0.25);
    animation-delay: .4s;
}

.LoadWrap_Windows span:nth-child(4) {
    top: 22px;
    left: 22px;
    background: rgba(162, 162, 162, 0.25);
    animation-delay: .6s;
}

@keyframes preloader_6_span {
    0% {
        transform: scale(1);
        background: rgba(63, 146, 186, 1);
    }
    50% {
        transform: scale(0.5);
        background: rgba(63, 146, 186, 0.5);
    }
    100% {
        transform: scale(1);
        background: rgba(63, 146, 186, 0.25);
    }
}