.Personas_Activo {
    background-color: #0099f2 !important;
}

.Personas_Inactivo {
    background-color: #f35b26 !important;
}

.Personas_Licencia {
    background-color: #f418c0 !important;
}

.Personas_Vacacion {
    background-color: #4bc76e !important;
}

.Personas_input {
    border: none !important;
    height: unset !important;
    color: rgb(51, 52, 49) !important;
    caret-color: rgb(51, 52, 49) !important;
}

.Personas_input:focus {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.Personas_input::placeholder {
    color: #9f9f9f;
}

.Personas_Tabla td {
    height: 5em;
    /* padding-left: 1.4em; */
    /* padding-right: 1.4em; */
    border-bottom: none !important;
    /* white-space: nowrap !important; */
}

.Personas_Tabla tr {
    border-bottom: none !important;
}

.Personas_Tabla th {
    height: 5em;
    /* white-space: nowrap; */
    /* padding-left: 1.4em; */
    /* padding-right: 1.4em; */
}

.Personas_floatopciones {
    -webkit-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    z-index: 9998;
}