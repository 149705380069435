.Inicio_AreaNotas {
    border: none !important;
    /* padding-left: 1em !important; */
    /* padding-right: 1em !important; */
}

.Inicio_AreaNotas:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.Inicio_BotonSubir {
    color: rgb(0, 153, 242) !important;
    cursor: pointer;
}

.Inicio_ContenedorHistorial {
    margin-top: 1em;
}

@media screen and (max-width: 600px) {
    .Inicio_ContenedorHistorial {
        padding: 0!important;
    }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
    .Inicio_ContenedorHistorial {
        padding: 0!important;
    }
}

@media screen and (min-width: 993px) {
    .Inicio_ContenedorHistorial {
        padding-left: 0 !important;
        margin-top: 0;
        padding-right: 1.5em !important;
    }
}