.DynamicForm_Entrada {
    border: 0.15em solid #dddddd !important;
    border-radius: 0.3em !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    box-shadow: none !important;
    color: rgb(51, 52, 49) !important;
}

.DynamicForm_Entrada:focus {
    border: 0.15em solid #3f92ba !important;
}