.Sidevinculos_vinculo:hover {
    /* background-color: rgba(0, 0, 0, 0.025) !important; */
    background-color: unset !important;
}

.Sidevinculos_vinculo:hover a {
    color: rgb(0, 153, 242) !important;
    /* font-weight: 500 !important; */
    /* font-size: 1.03em; */
}

.Sidevinculos_vinculo a{
    font-size: 1em;
    color: rgba(53, 53, 53,0.8) !important;
}

.Sidevinculos_vinculo:hover ion-icon {
    color: #3f92ba !important;
}

.Sidevinculos_activo {
    background-color: rgba(0, 0, 0, 0.025) !important;
    border-right: 0.35em solid rgb(0, 153, 242);
}

.Sidevinculos_activo:hover {
    background-color: rgba(0, 0, 0, 0.025) !important;
}

.Sidevinculos_activo a {
    font-weight: 500 !important;
    color: rgb(0, 153, 242) !important;
    font-size: 1em !important;
}

.Sidevinculos_activo ion-icon {
    color: rgb(0, 153, 242) !important;
}

/* .Sidevinculos_shadow {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
} */