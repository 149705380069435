.Headervinculo_floatboton {
    -webkit-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

/* .Headervinculo_header {
    background-image: url('../../imagenes/headerside.jpeg');
    background-size: cover;
} */