/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width: 992px) {
  header, main, footer {
    padding-left: 0;
  }
} */

body, #root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
}

main {
  flex: 1;
  overflow: hidden;
}

/* ScrollBar */

/* width */

::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}

/* Track */

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, .1);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .2);
  border-radius: 0.5em;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dropdown */

.dropdown-content {
  /* top: 100% !important; */
  background-color: transparent !important;
}

/* Sombra */

.miniSombra {
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.15);
}

.microSombra {
  /* -webkit-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.15); */
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.27);
}

/* Mismo tamaño columna */

.igualCol {
  display: flex;
  flex-wrap: wrap
}

.botonFlat {
  width: fit-content !important;
  -webkit-tap-highlight-color: transparent !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.botonFlatWrap ion-icon, .botonFlat {
  transition: color 0.5s;
  -webkit-tap-highlight-color: transparent !important;
}

.botonFlatWrap ion-icon:active, .botonFlat:active {
  transition: color 0.1s;
  color: rgba(167, 167, 167, 0.5) !important;
}

.noHighLight {
  -webkit-tap-highlight-color: transparent !important;
}

.noHighLightWrapper ion-icon {
  -webkit-tap-highlight-color: transparent !important;
  vertical-align: middle !important;
}

/* SideDash animado */

.abrirDashAnimado {
  transition: width 0.5s;
  /* width: 18rem */
}

.animarDashTexto {
  transition: font-size 0.5s;
}

.animarOpacidad {
  transition: opacity 1s;
}

/* Rotar en eje */

.spinear {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Selects */

.select-wrapper input {
  border: 0.15em solid #dddddd !important;
  border-radius: 0.3em !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  color: rgb(51, 52, 49) !important;
  margin-bottom: 0 !important;
  font-family: "Montserrat";
  font-size: 0.9em !important;
}

.select-wrapper input:focus {
  border: 0.15em solid #3f92ba !important;
}

.select-dropdown {
  background-color: white !important;
}

.select-dropdown li span {
  color: #3f92ba !important;
  font-family: "Montserrat";
  font-size: 0.9em !important;
  font-weight: 500;
}

.select-wrapper ul {
  background-color: white !important;
}

.select-dropdown li.disabled span {
  background-color: #ebebeb !important;
  color: #a4a7bb !important;
}

.select-wrapper .caret {
  z-index: 999 !important;
  pointer-events: none !important;
}

.show-on-hover {
  opacity: 0;
  transition: opacity 0.3s;
}

.show-on-hover:hover {
  opacity: 1;
}

.mensajeError {
  background-color: rgb(225, 112, 85) !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Evaluaciones */

.Evaluaciones_Entrada {
  width: 50% !important;
}

@media screen and (max-width: 600px) {
  .Evaluaciones_Entrada {
    width: 100% !important;
  }
  .NoScrollBar::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
  .NoScrollBar::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 993px) {}