.Contenedor {
    /* flex: 1; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
    color: white;
    font-family: "Montserrat";
    /* background-color: red; */
    /* width: 100%; */
}

.Login {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 30rem;
    height: 80vmax;
    max-height: 30rem;
    /* overflow-y: scroll; */
    /* background-color: red; */
}

.Titulo {
    color: rgb(0, 153, 242);
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
}

.Subtitulo {
    /* color: #e1e1e1; */
    color: #7f8c8d;
    font-size: 1.3rem;
    margin: 0;
}

.Registrarse {
    color: rgb(139, 67, 103);
    font-size: 1.3rem;
    margin: 0;
    font-weight: bold;
}

.Registrarse:hover {
    text-decoration: underline;
    cursor: pointer;
}

.wrapperInput {
    /* background-color: #292929; */
    background-color: rgba(242, 242, 242, 0.6);
    border-radius: 0.2rem;
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.Entrada {
    margin: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    padding-left: 1rem !important;
    /* color: #e1e1e1 !important; */
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    font-family: "Montserrat";
}

.Entrada::placeholder {
    color: #898989;
}

.botonOjo {
    cursor: pointer !important;
    /* pointer-events: none !important; */
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Olvidado {
    color: rgb(139, 67, 103);
    font-size: 1rem;
    margin: 0;
}

.Olvidado:hover {
    text-decoration: underline;
    cursor: pointer;
}

.botonSesion {
    background-color: rgb(0, 153, 242);
    width: 100% !important;
    /* border-radius: 0.5rem; */
    margin-top: 1rem;
}

.botonSesion:hover {
    background-color: #74b9ff !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}