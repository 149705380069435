.FullModal_Modal {
    margin: 0 !important;
    width: 100% !important;
    background-color: transparent;
    height: 100% !important;
    max-height: unset !important;
    top: 0 !important;
    border-radius: 0 !important;
    /* display: flex !important; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    /* Modal */
    /* position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    overflow-y: auto !important;
    will-change: top, opacity !important; */
}

.FullModal_Wrap {
    /* width: 90%; */
    /* height: 90%; */
    border-radius: 0.5rem;
    padding: 1em !important;
    /* max-width: 50rem; */
    max-height: 90% !important;
    overflow-y: auto !important;
    background-color: white;
}

.FullModal_Mostrar {
    display: flex !important;
}

.FullModal_Esconder {
    display: none !important;
}

.modal-overlay {
    opacity: 0.65 !important;
}