.DashWrap_ListaVinculos li {
    /* padding-left: 2em; */
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    /* border-top-right-radius: 2em; */
    /* border-bottom-right-radius: 2em; */
    /* cursor: pointer; */
}

.DashWrap_ListaVinculos a {
    display: block;
    /* font-size: 14px; */
    font-weight: 500;
    /* height: 48px; */
    /* line-height: 48px; */
    padding: 0.5em 1em;
}

.DashWrap_ListaVinculos li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

/* .DashWrap_ListaVinculos li:hover a {
    color: #0F3057 !important;
} */

.DashWrap_ListaVinculos li a {
    color: white;
    font-weight: 500;
}

.DashWrap_Contenedor {
    padding-top: 0.75em;
    max-width: unset !important;
    /* width: 100% !important; */
    /* padding-bottom: 2em; */
}

@media screen and (min-width: 993px) {
    .DashWrap_Panel {
        -webkit-box-shadow: inset 6px 0px 5px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: inset 6px 0px 5px 0px rgba(0, 0, 0, 0.05);
        box-shadow: inset 6px 0px 5px 0px rgba(0, 0, 0, 0.05);
    }
    .DashWrap_Contenedor {
        margin: 0 !important;
        width: 90% !important;
        /* margin-left: 5rem !important; */
        /* margin-right: 5rem !important; */
        /* width: unset !important; */
    }
}