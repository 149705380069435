.miBarra {
    -webkit-box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.025) !important;
    -moz-box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.025) !important;
    box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.025) !important;
    /* margin-bottom: 1em !important; */
    z-index: 996;
}

.miniMostrador {
    cursor: pointer;
}

.miniMostrador:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

@media screen and (min-width: 993px) {
    .miTitulo {
        float: left !important;
    }
}